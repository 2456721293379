export interface AddressInfo {
  streetAddress: string;
  country: string;
  zipCode: string;
  city: string;
  state: string;
  latitude: number | undefined;
  longitude: number | undefined;
}

export const parsePlaceResult = (data: google.maps.places.PlaceResult): AddressInfo => {
  const addressComponents = data?.address_components;

  const componentMap = {
    subPremise: '',
    premise: '',
    locality: '',
    street_number: '',
    route: '',
    country: '',
    postal_code: '',
    administrative_area_level_2: '',
    administrative_area_level_1: '',
  };

  if (addressComponents) {
    for (const component of addressComponents) {
      const componentType = component.types[0];
      if (componentMap.hasOwnProperty(componentType)) {
        (componentMap as any)[componentType] = component.long_name;
      }
    }
  }

  // const formattedAddress =
  //   `${componentMap.subPremise} ${componentMap.premise} ${componentMap.street_number} ${componentMap.route}`.trim();
  const formattedAddress =
    `${componentMap.street_number} ${componentMap.route}, ${componentMap.locality}, ${componentMap.administrative_area_level_1}, ${componentMap.country} ${componentMap.postal_code}`
      .split(',')
      .map((part) => part.trim())
      .filter((part) => part)
      .join(', ');
  const latitude = data?.geometry?.location?.lat();
  const longitude = data?.geometry?.location?.lng();

  return {
    streetAddress: formattedAddress,
    country: componentMap.country,
    zipCode: componentMap.postal_code,
    city: componentMap.administrative_area_level_2,
    state: componentMap.administrative_area_level_1,
    latitude: latitude,
    longitude: longitude,
  };
};
