import { PERMISSIONS_TYPES } from '../enums/permissions-types';
import { ROLE_TYPES } from '../enums/role-types';

export const ALLOWED_PERMISSIONS: Record<ROLE_TYPES, PERMISSIONS_TYPES[]> = {
  'Super admin': [
    PERMISSIONS_TYPES.VIEW_ADMIN_USER_LIST,
    PERMISSIONS_TYPES.CREATE_NEW_ADMIN,
    PERMISSIONS_TYPES.VIEW_ADMIN_PROFILE,
    PERMISSIONS_TYPES.CHANGE_ADMIN_PROFILE,
    PERMISSIONS_TYPES.SOFT_DELETE_ADMIN_USER,
    PERMISSIONS_TYPES.CREATE_THERAPIST,
    PERMISSIONS_TYPES.SET_PAY_RATE,
    PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS,
    PERMISSIONS_TYPES.MANAGE_THERAPIST_DETAILS,
    PERMISSIONS_TYPES.SOFT_DELETE_THERAPIST_PROFILE,
    PERMISSIONS_TYPES.VIEW_LIST_OF_SERVICE_ROLES,
    PERMISSIONS_TYPES.CREATE_SERVICE_ROLE,
    PERMISSIONS_TYPES.SOFT_DELETE_SERVICE_ROLE,
    PERMISSIONS_TYPES.CREATE_CLIENT,
    PERMISSIONS_TYPES.CREATE_GUARDIAN,
    PERMISSIONS_TYPES.VIEW_ALL_CLIENTS,
    PERMISSIONS_TYPES.EDIT_CLIENT,
    PERMISSIONS_TYPES.DELETE_CLIENT,
    PERMISSIONS_TYPES.EDIT_GUARDIAN,
    PERMISSIONS_TYPES.DELETE_GUARDIAN,
    PERMISSIONS_TYPES.CREATE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ALL_SERVICES,
    PERMISSIONS_TYPES.EDIT_SERVICE,
    PERMISSIONS_TYPES.SOFT_DELETE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS,
    PERMISSIONS_TYPES.APPROVE_OR_REJECT_REPORT,
    PERMISSIONS_TYPES.MANUAL_CREATION_OF_DOCUMENT,
    PERMISSIONS_TYPES.SOFT_DELETE_DOCUMENT,
    PERMISSIONS_TYPES.VIEW_FULL_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
    PERMISSIONS_TYPES.SOFT_DELETE_SHIFT,
    PERMISSIONS_TYPES.SOFT_DELETE_SHIFT_NO_SHOW,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.CANCEL_COMPLETED_SHIFT,
    PERMISSIONS_TYPES.VIEW_CANCEL_SHIFTS,
    PERMISSIONS_TYPES.DEPARTMENTS_MANAGE,
    PERMISSIONS_TYPES.VIEW_ACTIVITY_LOGS,
    PERMISSIONS_TYPES.VIEW_TIMESHEETS_LIST,
    PERMISSIONS_TYPES.APPROVE_PAYROLL,
    PERMISSIONS_TYPES.AUTHORIZATION_OF_THE_SERVICE,
    PERMISSIONS_TYPES.MANAGE_ON_HOLD_SERVICES,
    PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS,
    PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED,
    PERMISSIONS_TYPES.RESEND_INVITATION_LINK,
    PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ADP_ID,
    PERMISSIONS_TYPES.TRIGGER_SMS_FOR_SIGN_SHIFT,
    PERMISSIONS_TYPES.EDIT_DOCUMENT,
    PERMISSIONS_TYPES.VIEW_REPORTS,
    PERMISSIONS_TYPES.EDIT_COMMENTS,
    PERMISSIONS_TYPES.VIEW_BILLING_REPORT,
    PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC,
    PERMISSIONS_TYPES.VIEW_PAY_RATES_METRIC,
    PERMISSIONS_TYPES.TERMINATE_SERVICES,
    PERMISSIONS_TYPES.REINSTATE_SERVICES,
    PERMISSIONS_TYPES.REMOVE_COMMENTS,
  ],
  Admin: [
    PERMISSIONS_TYPES.VIEW_ADMIN_USER_LIST,
    PERMISSIONS_TYPES.VIEW_ADMIN_PROFILE,
    PERMISSIONS_TYPES.CREATE_THERAPIST,
    PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS,
    PERMISSIONS_TYPES.MANAGE_THERAPIST_DETAILS,
    PERMISSIONS_TYPES.SOFT_DELETE_THERAPIST_PROFILE,
    PERMISSIONS_TYPES.VIEW_LIST_OF_SERVICE_ROLES,
    PERMISSIONS_TYPES.CREATE_SERVICE_ROLE,
    PERMISSIONS_TYPES.SOFT_DELETE_SERVICE_ROLE,
    PERMISSIONS_TYPES.CREATE_CLIENT,
    PERMISSIONS_TYPES.CREATE_GUARDIAN,
    PERMISSIONS_TYPES.VIEW_ALL_CLIENTS,
    PERMISSIONS_TYPES.EDIT_CLIENT,
    PERMISSIONS_TYPES.DELETE_CLIENT,
    PERMISSIONS_TYPES.EDIT_GUARDIAN,
    PERMISSIONS_TYPES.DELETE_GUARDIAN,
    PERMISSIONS_TYPES.CREATE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ALL_SERVICES,
    PERMISSIONS_TYPES.EDIT_SERVICE,
    PERMISSIONS_TYPES.SOFT_DELETE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS,
    PERMISSIONS_TYPES.APPROVE_OR_REJECT_REPORT,
    PERMISSIONS_TYPES.MANUAL_CREATION_OF_DOCUMENT,
    PERMISSIONS_TYPES.SOFT_DELETE_DOCUMENT,
    PERMISSIONS_TYPES.VIEW_FULL_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
    PERMISSIONS_TYPES.SOFT_DELETE_SHIFT,
    PERMISSIONS_TYPES.SOFT_DELETE_SHIFT_NO_SHOW,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.CANCEL_COMPLETED_SHIFT,
    PERMISSIONS_TYPES.VIEW_CANCEL_SHIFTS,
    PERMISSIONS_TYPES.DEPARTMENTS_MANAGE,
    PERMISSIONS_TYPES.VIEW_ACTIVITY_LOGS,
    PERMISSIONS_TYPES.AUTHORIZATION_OF_THE_SERVICE,
    PERMISSIONS_TYPES.MANAGE_ON_HOLD_SERVICES,
    PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS,
    PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED,
    PERMISSIONS_TYPES.RESEND_INVITATION_LINK,
    PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ADP_ID,
    PERMISSIONS_TYPES.TRIGGER_SMS_FOR_SIGN_SHIFT,
    PERMISSIONS_TYPES.EDIT_DOCUMENT,
    PERMISSIONS_TYPES.VIEW_REPORTS,
    PERMISSIONS_TYPES.EDIT_COMMENTS,
    PERMISSIONS_TYPES.VIEW_TIMESHEETS_LIST,
    PERMISSIONS_TYPES.TERMINATE_SERVICES,
    PERMISSIONS_TYPES.REINSTATE_SERVICES,
    PERMISSIONS_TYPES.REMOVE_COMMENTS,
  ],
  Coordinator: [
    PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS,
    PERMISSIONS_TYPES.VIEW_LIST_OF_SERVICE_ROLES,
    PERMISSIONS_TYPES.VIEW_ALL_CLIENTS,
    PERMISSIONS_TYPES.VIEW_ALL_SERVICES,
    PERMISSIONS_TYPES.VIEW_CANCEL_SHIFTS,
    PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS,
    PERMISSIONS_TYPES.VIEW_FULL_SCHEDULE,
    PERMISSIONS_TYPES.AUTHORIZATION_OF_THE_SERVICE,
    PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS,
    PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED,
    PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE,
  ],
  Assistant: [
    PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS,
    PERMISSIONS_TYPES.MY_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.AVAILABLE_CLIENTS_LIST,
    PERMISSIONS_TYPES.MY_CLIENTS_LIST,
    PERMISSIONS_TYPES.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.PRESUBMIT_DOCUMENT,
    PERMISSIONS_TYPES.CHANGE_SHIFT_NOTES,
    PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST,
  ],
  'Assistant Limited': [
    PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS,
    PERMISSIONS_TYPES.MY_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.MY_CLIENTS_LIST,
    PERMISSIONS_TYPES.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.PRESUBMIT_DOCUMENT,
    PERMISSIONS_TYPES.CHANGE_SHIFT_NOTES,
    PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST,
  ],
  Therapist: [
    PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS,
    PERMISSIONS_TYPES.SUBMIT_DOCUMENT,
    PERMISSIONS_TYPES.MY_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.AVAILABLE_CLIENTS_LIST,
    PERMISSIONS_TYPES.MY_CLIENTS_LIST,
    PERMISSIONS_TYPES.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PERMISSIONS_TYPES.VIEW_MY_SHIFTS,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.CHANGE_SHIFT_NOTES,
    PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC,
    PERMISSIONS_TYPES.VIEW_PAY_RATES_METRIC,
    PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST,
  ],
  'Therapist Limited': [
    PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS,
    PERMISSIONS_TYPES.SUBMIT_DOCUMENT,
    PERMISSIONS_TYPES.MY_SCHEDULE,
    PERMISSIONS_TYPES.ADD_SHIFTS,
    PERMISSIONS_TYPES.ADD_SHIFTS_LIKE_NOT_ADMINS,
    PERMISSIONS_TYPES.CHANGE_SHIFT_DETAILS,
    PERMISSIONS_TYPES.CANCEL_SHIFT,
    PERMISSIONS_TYPES.MY_CLIENTS_LIST,
    PERMISSIONS_TYPES.CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
    PERMISSIONS_TYPES.VIEW_MY_SHIFTS,
    PERMISSIONS_TYPES.DECLINE_SERVICE,
    PERMISSIONS_TYPES.CHANGE_SHIFT_NOTES,
    PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC,
    PERMISSIONS_TYPES.VIEW_PAY_RATES_METRIC,
    PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST,
  ],
  Guardian: []
};
