"use client"
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@/components/form';

const FormSchema = z.object({
  notes: z.string().min(1, { message: 'Forms.note.required' }).max(1000, { message: 'Forms.note.max' }),
});

interface Props {
  onConfirm: (value: string) => void;
  title?: string;
  subtitle?: string;
  confirmBtnName?: string;
  isPending?: boolean;
}

export type ConfirmWithTextareaDialogRef = {
  open: () => void;
  close: () => void;
};

const ConfirmWithTextareaDialog = forwardRef<ConfirmWithTextareaDialogRef, Props>(
  ({ title = 'Are you sure?', subtitle = 'Please leave a comment', confirmBtnName = 'Confirm', onConfirm, isPending = false }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const t = useTranslations();

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
      },
    }));

    const form = useForm<z.infer<typeof FormSchema>>({
      mode: 'onChange',
      resolver: zodResolver(FormSchema),
    });

    function onSubmitHandle(data: z.infer<typeof FormSchema>) {
      onConfirm(data.notes);
    }

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{subtitle}</DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmitHandle)} className="space-y-4 pt-4">
              <div className="relative mb-10">
                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Textarea placeholder={t('Forms.note.placeholder')} className="resize-none" {...field} />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2">
                <Button disabled={isPending} type="submit" variant="destructive" size="lg" className="mt-5">
                  {confirmBtnName}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }
);

ConfirmWithTextareaDialog.displayName = 'ConfirmWithTextareaDialog';

export { ConfirmWithTextareaDialog };
