import { authOptions } from '@/authOptions';
import { ALLOWED_PERMISSIONS } from '../constants/allowed-premissions';
import { PERMISSIONS_TYPES } from '../enums/permissions-types';
import { ROLE_TYPES } from '../enums/role-types';
import { getServerSession } from 'next-auth/next';

/**
 * Checks if a given role is allowed to perform at least one of the provided permissions, or all of them if the compareRule is 'all'.
 * @param {ROLE_TYPES} role - The type of the role
 * @param {PERMISSIONS_TYPES[]} permissions - An array of permission types
 * @param {(('all'|'some'))} [compareRule] - Optional parameter to specify whether all or some of the allowed permissions must match (default is 'all')
 */
export function isAllowed(
  role: ROLE_TYPES,
  permissions: PERMISSIONS_TYPES[],
  compareRule: 'all' | 'some' = 'all'
): boolean {
  if (role) {
    if (ALLOWED_PERMISSIONS[role]) {
      if (compareRule === 'some') {
        return ALLOWED_PERMISSIONS[role].some((value) => permissions.includes(value));
      } else {
        const allowed = ALLOWED_PERMISSIONS[role].filter((value) => permissions.includes(value));
        return allowed.length === permissions.length;
      }
    } else {
      console.log('DEBUG LOG: WRONG ROLE TYPE!!!', role);
      return false;
    }
  }
  return false;
}

/**
 * Checks if any of the provided roles match the user's roles in the server-side session.
 * @param {ROLE_TYPES[]} roles - An array of role types
 */
export async function allowedForRolesServer(roles: ROLE_TYPES[]): Promise<boolean> {
  if (roles && roles.length > 0) {
    const session = await getServerSession(authOptions);
    if (session && session.user && session.user.roles) {
      return Promise.resolve(roles.includes(session.user.roles[0]));
    } else {
      console.log('DEBUG LOG: WRONG SESSION DATA');
      return Promise.resolve(false);
    }
  }
  return Promise.resolve(false);
}
